import { LayerThickness, Quote } from '@wurzelwerk/common-ui-components'
import { postFactory } from '@wurzelwerk/http-utils'
import { basePath } from './common'

interface CreateQuote {
  creationId: string
  cadFileId: string
  salt: string
  height: number
  layerThickness: LayerThickness
  tolerance: number
  createMeasurementProtocol: boolean
}

const url = `${basePath}/quotes`

const createQuote_ = postFactory<CreateQuote, Quote>(url)

export const createQuote = (token: string, quote: CreateQuote) => createQuote_({ token, body: quote })
