import { getFactory, postFactory } from '@wurzelwerk/http-utils'
import { basePath } from './common'
import { Order, UUID } from '@wurzelwerk/common-ui-components'

const url = `${basePath}/orders`

const _getOrders = getFactory<Order[]>(url)

export const getOrders = (token: string) => _getOrders({ token })

const _getOrderById = getFactory<Order>(url)

export const getOrderById = (token: string, id: string) => _getOrderById({ token, id })

interface OrderQuoteCreate {
  cadFileId: string
  quoteChecksum: string
  salt: string
}

interface OrderCreation {
  creationId: string
  billingAddressId: UUID
  shippingAddressId: UUID
  quotes: OrderQuoteCreate
}

const createOrder_ = postFactory<OrderCreation, Order>(url)

export const createOrder = (token: string, orderCreation: OrderCreation) =>
  createOrder_({ token, body: orderCreation })

const getOrderCount_ = getFactory<number>(`${url}/count`)

export const getOrderCount = (token: string, status: string) => getOrderCount_({ token, query: { status } })
