/** @jsx jsx */
import * as React from 'react'
import { css, jsx } from '@emotion/core'
import { verifyEmail } from '../../api/on-boarding'
import { Result } from '@wurzelwerk/http-utils'
import { useIntl } from 'react-intl'
import { Spin } from 'antd'
import NakedView from '../NakedView'

interface VerifyEmailProps {
  onBoardingId: string
}

const VerifyEmail = (props: VerifyEmailProps) => {
  const [result, setResult] = React.useState<Result<void> | undefined>()
  const intl = useIntl()

  React.useEffect(() => {
    if (props.onBoardingId) {
      verifyEmail(props.onBoardingId).then(setResult)
    }
  }, [props.onBoardingId])

  if (result) {
    return (
      <NakedView>
        <span
          css={css`
            font-size: 1.5em;
          `}
        >
          {intl.formatMessage({
            id: result.ok ? 'customer.email-verification.successful' : 'customer.email-verification.failed',
          })}
        </span>
      </NakedView>
    )
  }
  return <Spin />
}

export default VerifyEmail
