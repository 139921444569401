import * as React from 'react'
import { LaempeLayout } from '@wurzelwerk/common-ui-components'
import { Col, Row } from 'antd'

export interface NakedViewProps {
  children: React.ReactNode
}

const LaempeMessageCard = (props: NakedViewProps) => (
  <div>
    <LaempeLayout>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={20} offset={2}>
              {props.children}
            </Col>
          </Row>
        </Col>
      </Row>
    </LaempeLayout>
  </div>
)

export default LaempeMessageCard
