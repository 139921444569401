import * as React from 'react'
import { Col, Modal, PageHeader, Row, Steps, message } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { CompanyAddress, isDefined, Order, Quote, useAccessToken } from '@wurzelwerk/common-ui-components'
import PrintConfiguration from './PrintConfiguration'
import ShippingDetails from './ShippingDetails'
import OrderOverview from './OrderOverview'
import { v4 as uuidV4 } from 'uuid'
import { createOrder } from '../../../api/order'
import { Redirect } from 'react-router-dom'

const Step = Steps.Step

type OrderStep = 'print-config' | 'shipping-details' | 'overview'
const stepToId: Record<OrderStep, number> = {
  'print-config': 0,
  'shipping-details': 1,
  overview: 2,
}

const OrderCreate = () => {
  const [step, setStep] = React.useState<OrderStep>('print-config')
  const [quotes, setQuotes] = React.useState<Quote[]>([])
  const [creationId, setCreationId] = React.useState(uuidV4())
  const [order, setOrder] = React.useState<Order | undefined>()
  const [address, setAddress] = React.useState<CompanyAddress | undefined>()
  const intl = useIntl()
  const token = useAccessToken()

  const onPrintConfigured = (quotes: Quote[]) => {
    setQuotes(quotes)
    setStep('shipping-details')
  }

  const onShippingDetailsConfigured = (address: CompanyAddress) => {
    setAddress(address)
    setStep('overview')
  }

  const onCreateOrder = () => {
    if (!token || quotes.length === 0 || !address) {
      return
    }
    createOrder(token, {
      creationId,
      billingAddressId: address.id!, // FIXME
      shippingAddressId: address.id!, // FIXME
      quotes: quotes.map(q => ({
        cadFileId: q.cadFileId,
        quoteChecksum: q.checksum,
        salt: q.salt,
      })) as any,
    }).then(response => {
      if (response.ok) {
        // TODO message
        message.success(intl.formatMessage({ id: 'order.create.success' }))
        setOrder(response.data)
      } else {
        Modal.error({
          // TODO message
          title: intl.formatMessage({ id: 'error' }),
        })
      }
    })
  }

  if (isDefined(order)) {
    return <Redirect to="/orders" />
  }

  return (
    <>
      <PageHeader title={<FormattedMessage id="order.create" />} />
      <Row>
        <Col span={20} offset={2}>
          <Steps size="small" current={stepToId[step]} style={{ marginBottom: '40px' }}>
            <Step title={<FormattedMessage id="print-configuration" />} />
            <Step title={<FormattedMessage id="order.shipping-details" />} />
            <Step title={<FormattedMessage id="order.overview" />} />
          </Steps>
          {step === 'print-config' && (
            <PrintConfiguration creationId={creationId} onPrintConfigured={onPrintConfigured} />
          )}
          {step === 'shipping-details' && (
            <ShippingDetails onShippingDetailsConfigured={onShippingDetailsConfigured} />
          )}
          {step === 'overview' && <OrderOverview onCreateOrder={onCreateOrder} />}
        </Col>
      </Row>
    </>
  )
}

export default OrderCreate
