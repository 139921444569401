import * as React from 'react'
import { Modal, PageHeader, Table } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAccessToken, Order, Money, DateTime } from '@wurzelwerk/common-ui-components'
import { getOrders } from '../../api/order'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Redirect } from 'react-router'

const Column = Table.Column

type RedirectTo = 'create-order' | 'order-details'

const OrderList = () => {
  const [triggerLoading, setTriggerLoading] = React.useState<string>(new Date().toISOString())
  const [orders, setOrders] = React.useState<Order[]>([])
  const [selectedOrder, setSelectedOrder] = React.useState<Order | undefined>()
  const [redirectTo, setRedirectTo] = React.useState<RedirectTo | undefined>()
  const token = useAccessToken()
  const intl = useIntl()

  React.useEffect(() => {
    if (!token) {
      return
    }

    getOrders(token).then(response => {
      if (response.ok) {
        setOrders(response.data ?? [])
      } else {
        Modal.error({
          title: intl.formatMessage({ id: 'error' }),
          content: intl.formatMessage({ id: 'error.loading-orders' }),
        })
      }
    })
  }, [triggerLoading])

  if (redirectTo === 'create-order') {
    return <Redirect to="create-order" />
  }

  return (
    <>
      <PageHeader
        title={<FormattedMessage id="orders.title" />}
        extra={[<PlusCircleOutlined onClick={() => setRedirectTo('create-order')} />]}
      />
      <Table
        dataSource={orders}
        size="small"
        onRow={order => {
          return {
            onClick: () => setSelectedOrder(order),
          }
        }}
      >
        <Column
          key="status"
          title={<FormattedMessage id="order.status" />}
          dataIndex={['status']}
          render={status => <FormattedMessage id={`order.status.${status}`} />}
        />
        <Column
          key="amount"
          title={<FormattedMessage id="amount" />}
          dataIndex={['amount']}
          align="right"
          render={(_, position) => <Money amount={position as any} />}
        />
        <Column key="createdBy" title={<FormattedMessage id="created-by" />} dataIndex={['createdBy']} />
        <Column
          key="updatedAt"
          title={<FormattedMessage id="updated-at" />}
          dataIndex={['updatedAt']}
          render={d => <DateTime value={d} />}
        />
        <Column key="updatedBy" title={<FormattedMessage id="updated-by" />} dataIndex={['updatedBy']} />
      </Table>
    </>
  )
}

export default OrderList
