import * as React from 'react'
import {
  FileDetails,
  isDefined,
  PrintConfigV10,
  Quote,
  useAccessToken,
} from '@wurzelwerk/common-ui-components'
import { Button, Col, Form, InputNumber, Modal, Radio, Row, Switch, Tabs, Upload } from 'antd'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { basePath } from '../../../api/common'
import { UploadOutlined } from '@ant-design/icons'
import { UploadChangeParam } from 'antd/lib/upload/interface'
import { Draft, produce } from 'immer'
import debounce from 'lodash/debounce'
import { v4 as uuidV4 } from 'uuid'
import { createQuote } from '../../../api/quote'

const TabPane = Tabs.TabPane

const defaultPrintConfig: Partial<PrintConfigV10> = {
  type: 'V1_0',
  createMeasurementProtocol: true,
  layerThickness: 'MM014',
  tolerance: 10,
}

type FileDetailsByFileId = Record<string, FileDetails>
type QuotesByFileId = Record<string, Quote>

interface FileUploadButtonProps {
  onUploadCompleted: (fileDetailsByFileId: FileDetailsByFileId) => void
}

const FileUploadButton = (props: FileUploadButtonProps) => {
  const [fileDetailsByFileId, setFileDetailsByFileId] = React.useState<FileDetailsByFileId>({})
  const intl = useIntl()
  const token = useAccessToken()

  if (!token) {
    return <></>
  }

  const handleFileUpload = (info: UploadChangeParam) => {
    const file = info.file
    if (file.status === 'error') {
      Modal.error({
        title: intl.formatMessage({ id: 'error' }),
        content: intl.formatMessage({ id: 'error.file-upload-failed' }),
      })
    }
    if (file.status === 'done') {
      const fd = file.response as FileDetails
      const newState = produce(fileDetailsByFileId, (draft: Draft<FileDetailsByFileId>) => {
        draft[fd.id] = fd
      })
      setFileDetailsByFileId(newState)
      props.onUploadCompleted(newState as any)
    }
  }

  return (
    <Upload
      name="files"
      action={`${basePath}/files`}
      data={{ type: 'cad_project' }}
      multiple={true}
      headers={{ Authorization: `Bearer ${token}` }}
      onChange={handleFileUpload}
      showUploadList={false}
    >
      <Button style={{ marginBottom: '10px' }} icon={<UploadOutlined />}>
        Upload
      </Button>
    </Upload>
  )
}

interface PrintConfigurationFormProps {
  creationId: string
  fileDetails: FileDetails
  onQuoteChanged: (quote: Quote) => void
}

const PrintConfigurationForm = (props: PrintConfigurationFormProps) => {
  const [salt, setSalt] = React.useState<string | undefined>()
  const [printConfig, setPrintConfig] = React.useState<Partial<PrintConfigV10>>(defaultPrintConfig)
  const intl = useIntl()
  const token = useAccessToken()

  // trigger price calculation
  React.useEffect(() => {
    if (!isDefined(printConfig.height)) {
      return
    }
    setSalt(uuidV4())
  }, [
    printConfig.height,
    printConfig.createMeasurementProtocol,
    printConfig.layerThickness,
    printConfig.tolerance,
  ])

  // price calculation
  React.useEffect(() => {
    if (!isDefined(salt) || !isDefined(token)) {
      return
    }
    createQuote(token, {
      creationId: props.creationId,
      salt,
      cadFileId: props.fileDetails.id,
      ...printConfig,
    } as any).then(response => {
      if (response.ok) {
        const quote = response.data as Quote
        props.onQuoteChanged(quote)
      } else {
        Modal.error({
          title: intl.formatMessage({ id: 'error' }),
          // TODO
        })
      }
    })
  }, [salt])

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const onValuesChange = debounce((delta: Partial<PrintConfigV10>, allValues: PrintConfigV10) => {
    setPrintConfig(allValues)
  }, 200)

  return (
    <Form {...layout} initialValues={printConfig} onValuesChange={onValuesChange}>
      <Form.Item name="height" label={intl.formatMessage({ id: 'height' })}>
        <span>
          <InputNumber min={0} /> mm
        </span>
      </Form.Item>
      <Form.Item name="layerThickness" label={intl.formatMessage({ id: 'layer-thickness' })}>
        <Radio.Group>
          <Radio value="MM014">14mm</Radio>
          <Radio value="MM021">21mm</Radio>
          <Radio value="MM028">28mm</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="tolerance" label={intl.formatMessage({ id: 'tolerance' })}>
        <Radio.Group>
          <Radio value={10}>10mm</Radio>
          <Radio value={20}>20mm</Radio>
          <Radio value={30}>30mm</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="createMeasurementProtocol"
        valuePropName="checked"
        label={intl.formatMessage({ id: 'measurement-protocol' })}
      >
        <Switch />
      </Form.Item>
    </Form>
  )
}

interface CostDetailsProps {
  quotes: Quote[]
}

const CostDetails = (props: CostDetailsProps) => {
  return (
    <>
      <ul>
        {props.quotes.map(q => (
          <li key={q.id}>
            <FormattedNumber value={q.amount} style="currency" currency={q.currency} />
          </li>
        ))}
      </ul>
    </>
  )
}

interface PrintConfigurationProps {
  creationId: string
  onPrintConfigured: (quotes: Quote[]) => void
}

const PrintConfiguration = (props: PrintConfigurationProps) => {
  const [fileDetailsByFileId, setFileDetailsByFileId] = React.useState<FileDetailsByFileId>({})
  const [quotesByFileId, setQuotesByFileId] = React.useState<QuotesByFileId>({})
  const [allQuotesFilled, setAllQuotesFilled] = React.useState<boolean>(false)

  React.useEffect(() => {
    setAllQuotesFilled(Object.keys(fileDetailsByFileId).length === Object.keys(quotesByFileId).length)
  }, [Object.keys(fileDetailsByFileId).length, Object.keys(quotesByFileId).length])

  const onQuoteChanged = (quote: Quote) => {
    setQuotesByFileId(
      produce((draft: Draft<QuotesByFileId>) => {
        draft[quote.cadFileId] = quote
      })
    )
  }

  const onUploadCompleted = (fd: FileDetailsByFileId) => {
    setFileDetailsByFileId(
      produce((draft: Draft<FileDetailsByFileId>) => {
        Object.entries(fd).forEach(([k, v]) => {
          draft[k] = v
        })
      })
    )
  }

  const onPrintConfigured = () => {
    props.onPrintConfigured(Object.values(quotesByFileId))
  }

  return (
    <Tabs
      defaultActiveKey="0"
      tabBarExtraContent={{ left: <FileUploadButton onUploadCompleted={onUploadCompleted} /> }}
      tabPosition="left"
    >
      {Object.values(fileDetailsByFileId).map(fd => (
        <TabPane tab={fd.name} key={fd.id}>
          <Row>
            <Col span={18}>
              <PrintConfigurationForm
                creationId={props.creationId}
                fileDetails={fd}
                onQuoteChanged={onQuoteChanged}
              />
            </Col>
            <Col span={6}>
              <Row gutter={[6, 6]}>
                <Col span={24}>
                  <Button type="primary" disabled={!allQuotesFilled} onClick={onPrintConfigured}>
                    <FormattedMessage id="order.shipping-details" />
                  </Button>
                </Col>
              </Row>
              <Row gutter={[6, 6]}>
                <Col span={24}>
                  <CostDetails quotes={Object.values(quotesByFileId)} />
                </Col>
              </Row>
            </Col>
          </Row>
        </TabPane>
      ))}
    </Tabs>
  )
}

export default PrintConfiguration
