import * as React from 'react'
import { Steps, Row, Col, Form, Input, Space, Button, Radio } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { User as PersonData } from '../../domain/user'
import { Company as CompanyData } from '../../domain/company'

import './OnBoarding.css'
import { FormattedMessage, useIntl } from 'react-intl'
import { CreateOnBoarding } from '../../domain/on-boarding'
import { createOnBoarding } from '../../api/on-boarding'
import NakedView from '../NakedView'

const Step = Steps.Step

type StepNames = 'person' | 'company' | 'finalize'

const stepNamesToIndex: Record<StepNames, number> = {
  person: 0,
  company: 1,
  finalize: 2,
}

interface PersonProps {
  initialValues?: Partial<CreateOnBoarding>
  onValueChanged: (data: PersonData) => void
}

const Person = (props: PersonProps) => {
  const intl = useIntl()
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const validateMessages = {
    required: intl.formatMessage({ id: 'error.form.required' }),
    types: {
      email: intl.formatMessage({ id: 'error.form.invalid-email' }),
    },
  }

  const onNext = (values: any) => {
    props.onValueChanged(values['contactPerson'])
  }

  return (
    <Form
      {...layout}
      name="person"
      onFinish={onNext}
      validateMessages={validateMessages}
      initialValues={props.initialValues}
    >
      <Form.Item
        name={['contactPerson', 'salutation']}
        label={intl.formatMessage({ id: 'salutation' })}
        rules={[{ required: true }]}
        required={true}
      >
        <Radio.Group>
          <Radio value="mr">
            <FormattedMessage id="mr" />
          </Radio>
          <Radio value="mrs">
            <FormattedMessage id="mrs" />
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={['contactPerson', 'firstName']}
        label={intl.formatMessage({ id: 'first-name' })}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['contactPerson', 'lastName']}
        label={intl.formatMessage({ id: 'last-name' })}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['contactPerson', 'email']}
        label={intl.formatMessage({ id: 'email' })}
        rules={[{ required: true }, { type: 'email' }]}
        required={true}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['contactPerson', 'phoneNumber']}
        label={intl.formatMessage({ id: 'phone-number' })}
        rules={[{ required: true }]}
        required={true}
      >
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="next" />
        </Button>
      </Form.Item>
    </Form>
  )
}

interface CompanyProps {
  initialValues?: Partial<CreateOnBoarding>
  onValueChanged: (data: CompanyData) => void
  onPreviousStep: () => void
}

const Company = (props: CompanyProps) => {
  const intl = useIntl()
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const validateMessages = {
    required: intl.formatMessage({ id: 'error.form.required' }),
    types: {
      email: intl.formatMessage({ id: 'error.form.invalid-email' }),
    },
  }

  const onNext = (values: any) => {
    props.onValueChanged(values['company'])
  }

  return (
    <Form
      {...layout}
      name="person"
      onFinish={onNext}
      validateMessages={validateMessages}
      initialValues={props.initialValues}
    >
      <Form.Item
        name={['company', 'name']}
        label={intl.formatMessage({ id: 'company-name' })}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'address' })} style={{ marginBottom: 0 }} required={true}>
        <Form.Item
          name={['company', 'address', 'street']}
          rules={[{ required: true }]}
          style={{ display: 'inline-block', width: 'calc(80% - 8px)' }}
        >
          <Input placeholder={intl.formatMessage({ id: 'street' })} />
        </Form.Item>
        <Form.Item
          name={['company', 'address', 'houseNumber']}
          rules={[{ required: true }]}
          style={{ display: 'inline-block', width: 'calc(20% - 8px)', margin: '0 8px' }}
        >
          <Input placeholder={intl.formatMessage({ id: 'house-number' })} />
        </Form.Item>

        <Form.Item
          name={['company', 'address', 'zip']}
          rules={[{ required: true }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Input placeholder={intl.formatMessage({ id: 'zip' })} />
        </Form.Item>
        <Form.Item
          name={['company', 'address', 'city']}
          rules={[{ required: true }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
        >
          <Input placeholder={intl.formatMessage({ id: 'city' })} />
        </Form.Item>

        <Form.Item
          name={['company', 'address', 'countryCode']}
          rules={[{ required: true }]}
          required={true}
          messageVariables={{ label: 'foo' }}
        >
          <Input placeholder={intl.formatMessage({ id: 'country' })} />
        </Form.Item>
      </Form.Item>

      <Form.Item
        name={['company', 'salesTaxId']}
        label={intl.formatMessage({ id: 'sales-tax-id' })}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={['company', 'taxNumber']}
        label={intl.formatMessage({ id: 'tax-number' })}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name={['company', 'website']} label={intl.formatMessage({ id: 'website' })}>
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Space>
          <Button onClick={props.onPreviousStep}>
            <FormattedMessage id="previous" />
          </Button>
          <Button type="primary" htmlType="submit">
            <FormattedMessage id="next" />
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

type CreateStatus = 'success' | 'failure'

const OnBoarding = () => {
  const [currentStep, setCurrentStep] = React.useState<StepNames>('person')
  const [onBoardingData, setOnBoardingData] = React.useState<Partial<CreateOnBoarding>>({})
  const [createStatus, setCreateStatus] = React.useState<CreateStatus | undefined>()

  const onPersonChanged = (contactPerson: PersonData) => {
    setOnBoardingData({ ...onBoardingData, contactPerson })
    setCurrentStep('company')
  }

  const onCompanyChanged = (company: CompanyData) => {
    setOnBoardingData({ ...onBoardingData, company })
    setCurrentStep('finalize')
  }

  // eslint-disable-next-line
  React.useEffect(() => onCreateOnBoarding(), [currentStep])

  const onCreateOnBoarding = () => {
    if (currentStep !== 'finalize') {
      return
    }
    createOnBoarding(onBoardingData as any)
      .then(created => setCreateStatus(created ? 'success' : 'failure'))
      .catch(error => {
        console.error('Could not create on-boarding!', error)
        setCreateStatus('failure')
      })
  }

  return (
    <NakedView>
      <div className="container">
        <Row justify="center" gutter={[50, 50]}>
          <Col span={20}>
            <Steps size="small" current={stepNamesToIndex[currentStep]}>
              <Step title={<FormattedMessage id="contact-person" />} />
              <Step title={<FormattedMessage id="company" />} />
              <Step
                title={<FormattedMessage id="finalize-on-boarding" />}
                icon={currentStep === 'finalize' && !createStatus ? <LoadingOutlined /> : undefined}
              />
            </Steps>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          {currentStep === 'person' && (
            <Col span={18}>
              <Person onValueChanged={onPersonChanged} initialValues={onBoardingData} />
            </Col>
          )}
          {currentStep === 'company' && (
            <Col span={18}>
              <Company
                onValueChanged={onCompanyChanged}
                onPreviousStep={() => setCurrentStep('person')}
                initialValues={onBoardingData}
              />
            </Col>
          )}
          {currentStep === 'finalize' && (
            <Col span={18}>
              {createStatus === 'success' && <FormattedMessage id="on-boarding.create.success" />}
              {createStatus === 'failure' && <FormattedMessage id="on-boarding.create.failure" />}
            </Col>
          )}
        </Row>
      </div>
    </NakedView>
  )
}

export default OnBoarding
