/* eslint no-restricted-globals: 0 */
import * as React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { AuthContext, LaempeLayout, LogoutButton } from '@wurzelwerk/common-ui-components'
import { Breadcrumb, Layout, Menu } from 'antd'
import { FormattedMessage } from 'react-intl'
import { HomeOutlined, PrinterOutlined } from '@ant-design/icons'

interface SecuredViewProps {
  children: React.ReactNode
  onLanguageChange: (language: string) => void
}

const HomeMenu = () => (
  <Link to="/">
    <HomeOutlined />
    <FormattedMessage id="menu.home" />
  </Link>
)

const OrdersMenu = () => (
  <Link to="/orders">
    <PrinterOutlined />
    <FormattedMessage id="menu.orders" />
  </Link>
)

const SecuredView = (props: SecuredViewProps) => {
  const { keycloak } = React.useContext(AuthContext)

  if (!keycloak || !keycloak.authenticated) {
    return <Redirect to="/login" />
  }

  return (
    <LaempeLayout
      showLogout={true}
      defaultSelectedMenuItem="1"
      defaultLanguage="de_DE"
      languages={['de_DE', 'en_US']}
      onLanguageChange={props.onLanguageChange}
      menu={[
        { value: '/', label: <HomeMenu /> },
        { value: '/orders', label: <OrdersMenu /> },
      ]}
    >
      {props.children}
    </LaempeLayout>
  )
}

export default SecuredView
