import * as React from 'react'
import { Col, Modal, PageHeader, Row, Spin, Statistic } from 'antd'
import { isDefined, MoneyAmount, MoneyAmountInfo, useAccessToken } from '@wurzelwerk/common-ui-components'
import { getBalance } from '../../../api/account'
import { FormattedMessage, useIntl } from 'react-intl'
import { getOrderCount } from '../../../api/order'

const CustomerDashboard = () => {
  const [balance, setBalance] = React.useState<MoneyAmount | undefined>()
  const [orderCount, setOrderCount] = React.useState<number | undefined>()
  const token = useAccessToken()
  const intl = useIntl()

  React.useEffect(() => {
    if (!token) {
      return
    }
    getBalance(token).then(response => {
      if (response.ok) {
        setBalance(response.data)
      } else {
        Modal.error({
          // TODO create details
          title: intl.formatMessage({ id: 'error' }),
        })
      }
    })
  }, [])

  React.useEffect(() => {
    if (!token) {
      return
    }
    getOrderCount(token, 'created').then(response => {
      if (response.ok) {
        setOrderCount(response.data ?? 0)
      } else {
        Modal.error({
          // TODO create details
          title: intl.formatMessage({ id: 'error' }),
        })
      }
    })
  }, [])
  return (
    <>
      <PageHeader title={<FormattedMessage id="customer-dashboard.title" />} />
      <Row>
        <Col span={8}>
          {isDefined(balance) ? (
            <MoneyAmountInfo amount={balance} size="normal" title={<FormattedMessage id="account" />} />
          ) : (
            <Spin />
          )}
        </Col>
        <Col span={8}>
          {isDefined(orderCount) ? (
            <Statistic value={orderCount} title={<FormattedMessage id="open-orders" />} />
          ) : (
            <Spin />
          )}
        </Col>
      </Row>
    </>
  )
}

export default CustomerDashboard
