import * as React from 'react'
import { Button, PageHeader } from 'antd'
import { FormattedMessage } from 'react-intl'

interface OrderOverviewProps {
  onCreateOrder: () => void
}
const OrderOverview = (props: OrderOverviewProps) => {
  return (
    <>
      <PageHeader title={<FormattedMessage id="order.overview" />} />
      <Button onClick={props.onCreateOrder}>
        <FormattedMessage id="order.do-create" />
      </Button>
    </>
  )
}

export default OrderOverview
