/** @jsx jsx */
import * as React from 'react'
import { css, jsx } from '@emotion/core'
import { Row, Col, Card, Button, Space } from 'antd'

import { FormattedMessage, useIntl } from 'react-intl'
import { Redirect } from 'react-router'
import { AuthContext, LaempeLogo } from '@wurzelwerk/common-ui-components'

const Login = () => {
  const [selectedButton, setSelectedButton] = React.useState<'login' | 'register' | undefined>()
  const { keycloak } = React.useContext(AuthContext)
  const intl = useIntl()

  if (keycloak?.authenticated) {
    return <Redirect to="/" />
  }

  if (selectedButton === 'register') {
    return <Redirect to="/on-boardings" />
  }

  return (
    <Row
      justify="space-around"
      align="middle"
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 100vh;
      `}
    >
      <Col span={4}>
        <Card title={<LaempeLogo />} style={{ width: 300 }}>
          <p
            css={css`
              font-size: 1.5em;
              margin-top: 20px;
              margin-bottom: 40px;
            `}
          >
            {intl.formatMessage({
              id: 'login.text',
            })}
          </p>
          <Space>
            <Button onClick={() => keycloak!.login()}>
              <FormattedMessage id="login" />
            </Button>
            <Button onClick={() => setSelectedButton('register')}>
              <FormattedMessage id="register" />
            </Button>
          </Space>
        </Card>
      </Col>
    </Row>
  )
}

export default Login
