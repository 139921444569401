import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import 'antd/dist/antd.css'
import './index.css'
import Keycloak from 'keycloak-js'
import { basePath } from './api/common'

const keycloak = Keycloak(`${basePath}/configs/keycloak/customer-frontend`)

keycloak
  .init({
    onLoad: 'check-sso',
  })
  .catch(err => {
    console.warn('Could not verify authentication status!')
    return false
  })
  .finally(() => {
    ReactDOM.render(<App keycloak={keycloak} />, document.getElementById('root'))
  })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
