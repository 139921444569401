import { CreateOnBoarding } from '../domain/on-boarding'
import { basePath } from './common'
import { postFactory } from '@wurzelwerk/http-utils'

const url = `${basePath}/on-boardings`

export const createOnBoarding = (onBoarding: CreateOnBoarding): Promise<boolean> =>
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(onBoarding),
  }).then(response => {
    if (response.ok) {
      return true
    }
    console.error(`Received status [${response.status}].`)
    return false
  })

const verifyEmail_ = postFactory<void, void>(`${url}/:onBoardingId/verify-email`)

export const verifyEmail = (onBoardingId: string) => verifyEmail_({ pathParams: { onBoardingId } })
