import React from 'react'
import Main from './components/Main'
import { IntlProvider } from 'react-intl'

import './App.css'
import { HashRouter } from 'react-router-dom'
import { KeycloakInstance } from 'keycloak-js'
import {
  AuthProvider,
  messages as commonMessages,
  mergeMessages,
  LocaleContextProvider,
  parseLocale,
} from '@wurzelwerk/common-ui-components'
import de from 'date-fns/locale/de'
import enUS from 'date-fns/locale/en-US'

const messages = mergeMessages(commonMessages, {
  de: require('./translations/de.json'),
  en: require('./translations/en.json'),
})

const dateLocales: Record<string, object> = {
  de: de,
  en: enUS,
}

interface AppProps {
  keycloak: KeycloakInstance
}

const App = (props: AppProps) => {
  const [locale, setLocale] = React.useState<string>('de_DE')
  const { language } = parseLocale(locale)

  const onLanguageChange = (language: string) => setLocale(language)

  return (
    <LocaleContextProvider value={{ locale, dateLocale: (dateLocales[language] as any) ?? de }}>
      <IntlProvider locale={language} defaultLocale={language} messages={messages[language]}>
        <AuthProvider value={{ keycloak: props.keycloak }}>
          <HashRouter>
            <Main onLanguageChange={onLanguageChange} />
          </HashRouter>
        </AuthProvider>
      </IntlProvider>
    </LocaleContextProvider>
  )
}

export default App
