import * as React from 'react'
import { Route } from 'react-router-dom'
import Login from './login/Login'
import OnBoarding from './on-boarding/OnBoarding'
import CustomerDashboard from './customer/dashboard/CustomerDashboard'
import SecuredView from './SecuredView'
import VerifyEmail from './actions/VerifyEmail'
import OrderList from './order/OrderList'
import OrderCreate from './order/create/OrderCreate'

interface ViewProps {
  children: React.FC | React.ReactNode
  isSecured?: boolean
  matchParams: object
  onLanguageChange: (language: string) => void
}

const View = (props: ViewProps) => {
  const isSecured = props.isSecured ?? true
  const children = typeof props.children === 'function' ? props.children(props.matchParams) : props.children
  if (isSecured) {
    return <SecuredView onLanguageChange={props.onLanguageChange}>{children}</SecuredView>
  }
  return <>{children}</>
}

interface MainProps {
  onLanguageChange: (language: string) => void
}

const Main = (mainProps: MainProps) => {
  const routes = [
    { path: '/login', view: <Login />, isSecured: false },
    {
      path: '/on-boardings/:onBoardingId/verify-email',
      view: (props: any) => <VerifyEmail {...props} />,
      isSecured: false,
    },
    { path: '/on-boardings', view: <OnBoarding />, isSecured: false },
    { path: '/orders', view: <OrderList /> },
    { path: '/create-order', view: <OrderCreate /> },
    {
      path: '/',
      view: <CustomerDashboard />,
    },
  ]

  return (
    <>
      {routes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          exact={true}
          render={props => (
            <View
              onLanguageChange={mainProps.onLanguageChange}
              matchParams={props?.match?.params}
              isSecured={route.isSecured}
            >
              {route.view}
            </View>
          )}
        />
      ))}
    </>
  )
}

export default Main
