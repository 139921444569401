import * as React from 'react'
import { Button, Modal, PageHeader, Table } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { AddressDetails, CompanyAddress, isDefined, useAccessToken } from '@wurzelwerk/common-ui-components'
import { getCompanyAddresses } from '../../../api/company-address'

const Column = Table.Column

interface ShippingDetailsProps {
  onShippingDetailsConfigured: (address: CompanyAddress) => void
}

const ShippingDetails = (props: ShippingDetailsProps) => {
  const [addresses, setAddresses] = React.useState<CompanyAddress[]>([])
  const [selectedAddress, setSelectedAddress] = React.useState<CompanyAddress | undefined>()
  const token = useAccessToken()
  const intl = useIntl()

  React.useEffect(() => {
    if (!token) {
      return
    }
    getCompanyAddresses(token).then(response => {
      if (response.ok) {
        setAddresses(response.data!)
      } else {
        Modal.error({
          // TODO
          title: intl.formatMessage({ id: 'error' }),
        })
      }
    })
  }, [])

  const onShippingDetailsConfigured = () => {
    if (!selectedAddress) {
      return
    }
    props.onShippingDetailsConfigured(selectedAddress)
  }
  return (
    <>
      <PageHeader title={<FormattedMessage id="order.shipping-details" />} />
      {selectedAddress && <AddressDetails address={selectedAddress.address} />}
      <Table
        dataSource={addresses}
        size="small"
        onRow={address => {
          return {
            onClick: () => setSelectedAddress(address),
          }
        }}
      >
        <Column key="type" title={<FormattedMessage id="type" />} dataIndex="type" />
        <Column
          key="address"
          title={<FormattedMessage id="address" />}
          dataIndex="address"
          render={a => <AddressDetails address={a} />}
        />
      </Table>
      <Button disabled={!isDefined(selectedAddress)} onClick={onShippingDetailsConfigured}>
        <FormattedMessage id="order.overview" />
      </Button>
    </>
  )
}

export default ShippingDetails
